import axios from 'axios';
import WordleResult from '../components/WordleResult.vue';
export default {
  data: function data() {
    return {
      key: this.$route.params.key,
      source: null,
      players: [],
      status: '',
      verified: false,
      player_name: null,
      addplayer: false,
      showGame: false,
      showShare: false,
      isOwner: false,
      roomUrl: this.BASE_URL + 'room/' + this.$route.params.key,
      lastResults: null
    };
  },
  props: {},
  components: {
    'wordle-result': WordleResult
  },
  mounted: function mounted() {
    var $that = this;
    axios.get('room/' + this.key, {
      withCredentials: true
    }).then(function (resp) {
      if (resp.status == 200) {
        $that.verified = resp.data.verified;

        if ($that.verified) {
          $that.players = resp.data.room.players;
          $that.isOwner = resp.data.room.is_owner;
          $that.lastResults = resp.data.room.last_game_result;
          if (resp.data.game_id != null) $that.$toast.info('Game currently in progress', {
            position: 'top'
          });
          $that.initEventSource();
        } else {
          $that.addplayer = true;
        }
      } else if (resp.status == 404) {// show room not found
      }
    });
  },
  unmounted: function unmounted() {
    if (this.source != null) this.source.close();
    this.source = null;
  },
  methods: {
    initEventSource: function initEventSource() {
      var $that = this;

      if (this.source != null) {
        this.source.close();
        this.source = null;
      } // get short lived token then init


      axios.post('room/slt', {}, {
        withCredentials: true
      }).then(function (resp) {
        $that.source = new EventSource($that.API_URL + 'room/messages?slt=' + resp.data);

        $that.source.onmessage = function (event) {
          console.log('onmessage: ' + event.data);

          if (event.data != '') {
            var obj = JSON.parse(event.data);
            $that.players = obj.players;
            if (obj.game_id != null) // a current game has begun, redirect
              $that.$router.push('/game/' + obj.game_id);
          }
        };

        $that.source.onopen = function (event) {
          console.log('onopen');
        };

        $that.source.onerror = function (event) {
          console.log('onerror');
          setTimeout(function () {
            $that.initEventSource();
          }, 2000); // wait a couple seconds and retry
        };
      });
    },
    joinRoom: function joinRoom() {
      var $that = this;
      axios.post('room/' + this.key + '/player', {
        name: this.player_name
      }, {
        withCredentials: true,
        validateStatus: false
      }).then(function (resp) {
        if (resp.status == 200) {
          $that.verified = true;
          $that.addplayer = false;
          $that.players = resp.data.players;
          $that.initEventSource();
        } else if (resp.status == 400) {
          $that.$toast.error(resp.data.Message, {
            position: 'top'
          });
        }
      });
    },
    startGame: function startGame() {
      axios.post('game', {}, {
        withCredentials: true
      }).then(function (resp) {// nothing to do here. room occupants will be alerted via broadcast
      });
    },
    copyUrl: function copyUrl() {
      var el = document.createElement('textarea');
      el.value = this.roomUrl;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      var selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    }
  }
};