import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3fe7396f"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "mini-block"
};
var _hoisted_2 = {
  class: "mini-row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.attempts, function (attempt, i) {
    return _openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attempt, function (char) {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["mini-letter", {
          gray: char == '0',
          'orange': char == '1',
          'green': char == '2'
        }])
      }, null, 2);
    }), 256))]);
  }), 256))]);
}